
@import "src/assets/custom";
.date-picker {
  width: auto;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0;
  color: green !important;
}

.crawl-time-component {
  display: flex;
}

.time-travel-btn {
  color: #1997c6;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.time-picker {
  width: 120px;
  border-radius: 0;
  border-right: none;
}
